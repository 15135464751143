
.abs_div {
    position: absolute;
    top: 30px;
    left: 30px;   
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 5;
}
.abs_btn {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
}
.abs_lang_select {
    position: absolute;
    top: 5px;
    left: 45vw;
    z-index: 5;
}
.user_div {
    flex-direction: column;
    gap:8px;
    justify-content:flex-start;
    font-size: 20px;
}
 
.home_screen_wrapper {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    
    position: relative;
    overflow: hidden !important;
    
}
.logo_container {
    width: 100vw;
    height: 100px;
    margin-top: 90px;
    margin-bottom: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
    font-size: var(--txt-bold);
    color: var(--yellow-txt);
    font-weight: 700;
}
.home_screen_body_holder {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.home_screen_options_container {
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    justify-content: flex-start;    
    min-height: 100%;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.063);
    border-radius: 0 15px 0 0 ;
    padding-right: 20px;
   
}
.home_screen_body_txt {
    width: 100%;
    min-height: auto;
    overflow-y: scroll;
    padding-bottom: 210px;   
}

.home_screen_article_container {
    display: flex;
    flex-direction: column;
}
.home_screen_article_containe_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:10px;
}
.home_screen_body_txt h3, h4 {
    color: var(--yellow-txt);
    line-height: 35px;
    margin-top: 20px;
}

.home_screen_article {
    width: 350px;
    margin: 20px;
    text-align: justify;
    font-size: var(--txt-bold-gap);
}
.img_wrapper {
    height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
}
.home_position_pic {
    background-image: url('../../src/assets/home_position.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 700px;
    height: 250px;
    border-radius:10px;
    margin: 80px 0 0 30px;
}
.touch_typing_pic {
    background-image: url('../../src/assets/touch_typing.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 700px;
    height: 500px;
    border-radius:10px;
    margin: 80px 0 0 30px;
}
.no_net_pic {
    background-image: url('../../src/assets/no_net.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 360px;
    height: 300px;
    border-radius:10px;
    margin: 50px 0 0 30px;
}
.lang_intro_description {
    font-size: var(--txt-bold-gap);
}
.flags_container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}
.flag_item1 {
    background-image: url('../../src/assets/uk_round.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 40px;
    height: 40px;
    border-radius:50%; 
    cursor: pointer;  
}
.flag_item2 {
    background-image: url('../../src/assets/ukr_round.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 40px;
    height: 40px;
    border-radius:50%; 
    cursor: pointer;
    margin: 0 30px;   
}
.flag_item3 {
    background-image: url('../../src/assets/ksa_round.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 40px;
    height: 40px;
    border-radius:50%; 
    cursor: pointer;   
}
.lang_intro_wrapper {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin: 30px 0 0 35px;
}


.italic_txt {
    font-style: italic;
}
.link {
    font-size: var(--txt-key_board);
    color: var(--wh-text);
    margin-bottom: 20px; 
    cursor: pointer;
}
/* .link:hover {   
    text-decoration: underline;
    text-underline-offset: 7px;    
    transform: scale(1.05) translateY(-3px);
} */
.no_sound {
    color:rgb(198, 198, 198);
    cursor:not-allowed;
    font-size: large;
   }

   .rouned_btn {
   margin-left: 10vw;   
}

.note_notice_txt {
    font-size: var(--txt-bold-gap);
    font-weight: 400;
}

