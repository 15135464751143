:root {  
  --wh-text: #ffffff;
  --dark-shade-bg: rgba(1, 1, 1, 0.249);
  --light-shade-bg: rgba(255, 255, 255, 0.1);
  --darker-text: rgba(30, 28, 28, 1);
  --contrast_txt: #15B1ED;
  --light-bg: rgba(221, 219, 232, 0.632);
  --border-color: #777;
  --border-radius: 10px;
  --main-bg: #071621;
  --txt-black: calc(32px + (12 + 12 * 0.7) * ((100vw - 320px) / 1280));
  --txt-black-gap: calc(45px + (14 + 14 * 0.7) * ((100vw - 320px) / 1280));
  --txt-bold: calc(24px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  --txt-bold-gap: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  --txt-bold-small: calc(7px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  --txt-key_board: calc(11px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  --gap-big-txt-sm-screen: 40px;
  --txt-normal: calc(14px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  --txt-midi: calc(12px + (8 + 8 * 0.7) * ((100vw - 320px) / 1280));
  --form-bg:#041726CC; 
  --form-input-bg:#041726CC;
  --notification-bg: #213b44;
  --training-input-bg: #adc8d1;
  --training-input-darker-bg: #568c9f;
  --info-bg: #1f9bcf;
  --yellow-txt: #FCCA09;
  --green-bg: rgb(0, 202, 17);
  --invi-txt: rgba(254, 254, 254, 0);

  --btn-bg-green:rgb(0, 217, 0);
  --btn-bg-blue:rgb(82, 82, 253);
  --btn-bg-roze: rgb(255, 111, 98);
  --btn-bg-orange: orange;
  --btn-bg-yellow:yellow;
  /* --a: 0deg;
    --b: 90deg;
    --c: 180deg;
    --d: 270deg; */
    /* --btn-bg-orange: #d06e2d; */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

*::-webkit-scrollbar {
  width: 0.7em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--main-bg);
  border: none;
  background-color: var(--main-bg);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  outline: 1px solid slategrey;
  border-radius: 10px;
  width: 100px;
 
}


