.gear_whele_abs_div {
    
    position: absolute;
    top: 30px;
    right: 30px;   
    width: 50px;
    height: 50px;
    cursor:pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 2;
    transition: 0.8s ease-in-out;
}
.lang_select_abs_div {    
    position: absolute;
    top: 30px;
    right: 90px;   
    width: 120px;
    height: 50px;
    cursor:pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 2;
    transition: 0.8s ease-in-out;
    background-color: transparent;
    border: none;
    font-size: 1.8em;
    color: #FFFFFF;   
}
.lang_select_abs_div option {
      border-radius:15px;
    background-color: transparent;
    color: #545353;
    font-size: 1.2em;
}
.gear_whele_abs_div:hover {
    transform: scale(1.20) translateX(-1px) !important;
    
}
.gear_wheel {
    transform: rotate(180deg);    
    transition: 1s ease-in-out;
}
.gear_wheel_init {
    transform: rotate(0);
    transition: 1s ease-in-out;
}

.settings_wrapper {
    padding-top: 70px;
    padding-bottom: 30px;
    position: absolute !important;
    top: 0px;
    right: 0px;   
    width: 250px;
    height: 570px;
    background-color:var(--light-shade-bg);
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    z-index: 1;
    border-radius: 0 0 0 50px ;
    overflow-y: auto;
    transition: 1s ease-in-out;
}
.hide_wrapper {
    right: -250px;   
}
.show_wrapper {
    right: 0px;
}
.displayNone {
    display: none;
}
.rel_blck {
    position: relative;
}
.menu_header {
    margin: 20px auto;
    font-weight: 400;
    position: sticky; 
    top: 0;
    z-index: 1;
    background-color:rgba(67, 86, 102, 0.658);
    width: 210px;
    height: 40px;
    padding: 10px 0 10px 0;
    border-radius:5px;
}
.bg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: scale(1.00) translateX(-0px) !important;

    width: 200px;
    min-height: 50px;
    cursor: pointer;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-size: larger;

    border-radius:10px;
}

.outlined {
    outline: solid 2px white !important;
    transform: scale(1.05) translateX(-5px) !important;
    font-size: large;
    transition: all 0.3s ease-in-out;
}
