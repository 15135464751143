.action_btn {
    border-radius: 10px;
    animation: change_colour 20s ease-in-out infinite;
}
@keyframes change_colour {
    0% {
      background-color: #4bbf73 ;
    }
    20% {
      background-color: yellow ;
      color:blue
    }
    40% {
      background-color: goldenrod ;
      color:blue
    }
    60% {
      background-color: blue ;
      color: #FFFFFF
    }
    80% {
      background-color: violet ;
    }
    100% {
      background-color: #4bbf73 ;
    }
  }