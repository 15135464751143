.card {
    background-color: var( --training-input-bg);
    width: 100%;
    max-width: 500px;
    color: var(--darker-text);
    font-size: var(--txt-bold);
    border-radius: 20px;
}
.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    width: 100%;
    padding: 0;
    margin: 0;    
}
.no_page_btn {
    border-radius:20px;
    color: var(--darker-text);
    width: 90%;
    font-size: var(--txt-bold-gap);
    margin-bottom: 30px;
}
 
.shade_scr {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color:rgba(1, 1, 1, 0.77);
    z-index: 5;

    display: flex;
    align-items:center;
    justify-content: flex-start;
    padding-left: 15vw;
}
.shade_scr_txt_container {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
   /* background-color:blueviolet; */
    width: 45vw;
}
.shade_scr_txt_item {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
}
.shade_scr_sub_container {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:flex-start;
    margin-top: 10px;
    /* background-color: blueviolet; */
}

.shade_scr_txt {
    padding-left: 10px;
    font-size: var( --txt-key_board);
}
.shade_scr_txt0 {
  
    font-size: var( --txt-key_board);
}
.shade_scr_txt1 {
    font-size: var(--txt-black);
}
.shade_scr_txt2 {
    font-size: var(--txt-bold);
}
.shade_scr_btn_container {
    /* background-color:cadetblue; */
    height: 50%;
    width: 25vw;

    display: flex;
    flex-direction: column;
    align-items:flex-end;
    justify-content: flex-end;
}
.rounded_btn {
    border-radius: 15px;
}
.volume_box {
    position:absolute;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 50%;
   
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 1;
   border: solid 1px;
}
.volume_box:hover {
    background-color: rgba(85, 85, 85, 0.708);
}


