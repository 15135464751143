.form_container {
    height: 100%;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;
    border-radius: 30px;
    background-color: var(--notification-bg);
}
.form_container h5 {
    color:#FFFFFF;
}
.form_top_portion {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-evenly; 

    border-radius: 20px 20px 0 0;  
}
.form_lower_portion {
    height: 40%;
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;  
}



.form_col1 {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;  
    gap:10px; 
    padding-top: 15px;
}
.form_col2 {
    padding-top: 20px;
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;   
}
.form_control {
    margin-top: 10px;
    /* background-color: transparent; */
    border-radius: 20px;
    width: 80%;
    
}
.under_line {
    height: 1px;
    width: 90%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 1.08%, rgba(255, 255, 255, 0.3) 52.9%, rgba(255, 255, 255, 0) 100%);
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}

.rounded_btn {
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
    width: 100%;
 }

 @media screen and (max-width: 540px) {
    .form_top_portion {
        margin-top: 20px;
        flex-direction: column;       
        justify-content: center;     
    }
    .form_lower_portion {
        height: 40%;
        width: 250px;
        
    }

    .form_col1 {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;   
    }
    .form_col2 {
        padding-top: 0;
        flex-direction: column;
        align-items:center;
        justify-content: flex-start;   
    }
}

.btn_text {
    display:block;
}
.btn_text_small {
    display: none;
}